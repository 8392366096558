
import React from 'react';
import GoogleMaps from 'simple-react-google-maps';

function MapContainer(){ 
    return (
        <>
            <GoogleMaps 
                apiKey="AIzaSyCJlYgSidUhCgLU8M-rvEJEHJoJYr7VUP0"
                style={{ height: "500px", width: "100%" }}
                zoom={20}
                center={{
                    lat: -34.8647254,
                    lng: -57.9097946
                }}
                // markers={{lat: -34.864798, lng: -57.909674}}
            />
        </>

    );
  }

export default MapContainer;