/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col
} from "reactstrap";

class SimpleFooter extends React.Component {
  render() {
    return (
      <>
        <footer className="footer bg-secondary">
          <Container>
            <Row className=" row-grid align-items-center mb-5">
            </Row>
            <hr />
            <Row className=" align-items-center justify-content-md-between">
              <Col md="4" sm="12">
                <div className=" copyright">
                  © {new Date().getFullYear()}{" "}
                  Centro de día Ensueño.
                </div>
              </Col>
              <Col md="8" sm="12">
                <Nav className="nav-footer justify-content-end">
                  <NavItem>
                    <NavLink
                      href="/"
                    >
                      INICIO
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="/nosotros"
                    >
                      NOSOTROS
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <Link
                      className="nav-link"
                      to="/programas"
                    >
                      PROGRAMAS
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link
                      className="nav-link"
                      to="/talleres"
                    >
                      TALLERES
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link
                      className="nav-link"
                      to="/galeria-fotos"
                    >
                      GALERIA DE FOTOS
                    </Link>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="/#contacto"
                    >
                      CONTACTO
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    );
  }
}

export default SimpleFooter;
