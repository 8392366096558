import Cocina from '../../assets/img/galeria-imagenes/talleres/cocina.jpg';
import Manualidades from '../../assets/img/galeria-imagenes/talleres/manualidades.jpg';
import Manualidades1 from '../../assets/img/galeria-imagenes/talleres/manualidades1.jpg';
import Manualidades2 from '../../assets/img/galeria-imagenes/talleres/manualidades2.jpg';
import Pintura from '../../assets/img/galeria-imagenes/talleres/pintura.jpg';

export const photosActividades = [
    {
      src: Cocina,
      width: 3,
      height: 3
    },
    {
      src: Manualidades,
      width: 3,
      height: 2
    },
    {
      src: Manualidades1,
      width: 3,
      height: 2
    },
    {
      src: Manualidades2,
      width: 2,
      height: 2
    },
    {
      src: Pintura,
      width: 3,
      height: 2
    },
  ];
  