
import React, { useEffect } from 'react';
import { Card, CardImg, Col, Container, Row } from 'reactstrap';

function NuestrosProgramas(props){ 

    useEffect(() => {
        if (window.location.hash){
            document.getElementById(`${window.location.hash}`).scrollIntoView()
        }
        
      }, [])
    return (
        <>
            <section className="section section-lg pt-6 bg-secondary mt-5">
            <Container>
              <Row className="row-grid align-items-center mb-5" id="nuestrosProgramas">
                <Col lg="12">
                  <div className="align-component-enter">
                      <h4 className="display-3">Nuestros programas</h4>
                      <p className="align-component-justify lead">
                        Cada usuario del Centro de Dia junto con el equipo interdisciplinario realiza una planificación centrada en la persona, seleccionando las actividades, espacios y talleres de trabajo, a través de los apoyos que cada uno necesita. Los objetivos del plan personal tienen en cuenta las dimensiones Bienestar emocional, Relaciones interpersonales, Bienestar material, Desarrollo personal, Bienestar físico, Autodeterminación, Inclusión social y Derechos 
                      </p>
                  </div>
                </Col>
              </Row>
              <Row id="#autodeterminacion" className="mb-6">
                <Col md="6" className="order-last order-md-first">
                  <Card className="bg-default shadow border-0">
                    <CardImg
                      alt="..."
                      src={require("assets/img/theme/talleres/autodeterminacion.jpg")}
                      top
                    />
                  </Card>
                </Col>
                <Col md="6" className="order-last order-sm-first">
                  <div className="pl-md-5">
                    <h3>Autodeterminación</h3>
                    <p className="lead">
                        Implica la combinación de habilidades, conocimiento y creencias que capacitan a una persona para comprometerse en una conducta autónoma, autorregulada y dirigida a metas. Para la autodeterminación es esencial la comprensión de las fuerzas y limitaciones de uno, junto con la creencia de que uno es capaz y efectivo. Refleja la creencia que todas las personas tienen derecho a elegir sus propias vidas. ( Wehmeyer & Schwartz, 1997).
                    </p>
                  </div>
                </Col>
              </Row>
              <Row id="#transicion-vida-adulta" className="mb-6">
                <Col md="6" className="order-last order-sm-first">
                  <div className="pl-md-5">
                    <h3>Transición a la vida adulta</h3>
                    <p className="lead">
                        La transición a la vida adulta es un proceso multidimensional que incluye la necesidad de trabajar competencias y habilidades relacionadas con la autonomía, el cuidado personal, el conocimiento del entorno, y habilidades sociales. 
                    </p>
                  </div>
                </Col>
                <Col md="6">
                  <Card className="bg-default shadow border-0">
                    <CardImg
                      alt="..."
                      src={require("assets/img/theme/talleres/vida-adulta-transicion.jpg")}
                      top
                    />
                  </Card>
                </Col>
              </Row>
              <Row id="#formacion-laboral" className="mb-6">
                <Col md="6"  className="order-last order-md-first">
                  <Card className="bg-default shadow border-0">
                    <CardImg
                      alt="..."
                      src={require("assets/img/theme/talleres/formacion-laboral.jpg")}
                      top
                    />
                  </Card>
                </Col>
                <Col md="6" className="order-last order-sm-first">
                  <div className="pl-md-5">
                    <h3>Formación Laboral</h3>
                    <p className="lead">
                    Propone dar oportunidades de formación laboral a los usuarios del Centro de Día a través de la puesta en marcha de talleres productivos de autogestión. La propuesta incluye la formación y capacitación en los talleres ocupacionales que se desarrollan en el Servicio, así como también, la de fomentar los aprendizajes de competencias laborales generales que favorezcan el desarrollo personal, bienestar emocional y la autodeterminación.
                    </p>
                  </div>
                </Col>
              </Row>
              <Row id="#inclusion-social" className="mb-6">
                <Col md={6} className="order-last order-sm-first">
                  <div className="pl-md-5">
                    <h3>Inclusión social y comunitaria</h3>
                    <p>
                        Se propone promover, fomentar y propiciar la participación de los usuarios en diferentes espacios internos como externos donde se trabajen actividades artísticas, culturales, deportivas, así como también, visitas y salidas recreativas y culturales. 
                    </p>
                    <p>
                        Estas propuestas tienen como finalidad la inclusión social y comunitaria para el desarrollo de sus intereses personales. Permiten mejorar las habilidades sociales, competencias, la autoconfianza, la identidad y el autoconcepto positivo; también se promueve la socialización, la interacción social, el aprendizaje en equipo y la resolución de problemas. 
                    </p>
                  </div>
                </Col>
                <Col md={6}>
                  <Card className="bg-default shadow border-0">
                    <CardImg
                      alt="..."
                      src={require("assets/img/theme/talleres/inclusion-social.jpg")}
                      top
                    />
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </>
    );
}
export default NuestrosProgramas;