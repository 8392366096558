import classnames from "classnames";
import React, { useState } from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Spinner,
  UncontrolledAlert,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import contactoService from "backend/ContactoService";
import MapContainer from "./MapContainer";
import { Link } from "react-router-dom/dist";

function ContactoLanding() {
  const [nameFocused, setNameFocused] = useState(false);
  const [emailFocused, setEmailFocused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const contactoForm = useFormik({
    initialValues: {
      nombre: "",
      email: "",
      mensaje: "",
    },
    validationSchema: Yup.object({
      nombre: Yup.string().required("Requerido"),
      email: Yup.string()
        .email("Dirección de e-mail inválida")
        .required("Requerido"),
      mensaje: Yup.string()
        .min(15, "Debe tener más de 15 caracteres")
        .required("Requerido"),
    }),
    onSubmit: (values) => {
      setIsLoading(true);
      contactoService(values)
        .then((response) => {
          setIsLoading(false);
          setIsSuccess(true);
          console.log(response);
        })
        .catch((err) => {
          setIsLoading(false);
          setIsError(true);
        });
      contactoForm.resetForm();
    },
  });

  return (
    <>
      <section className="section section-lg section-contact-us">
        <Container>
          <Row>
            <Col lg="12">
              <div className="pl-4 align-component-enter">
                <h4 className="display-3">Contactate con nosotros</h4>
              </div>
            </Col>
            <Row className="justify-content-center ">
              <Col lg={6} sm={12}>
                <ul className="list-unstyled p-lg-5">
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <Badge className="badge-circle mr-3" color="default">
                          <i className="ni ni-email-83" />
                        </Badge>
                      </div>
                      <div>
                        <p className="mb-0" mail>
                          <Link
                            to="javascript:void(0)"
                            onClick={() =>
                              (window.location =
                                "mailto:contacto@centrodediaensuenio.com.ar")
                            }
                          >
                            contacto@centrodediaensuenio.com.ar
                          </Link>
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <Badge className="badge-circle mr-3" color="default">
                          <i class="fa fa-phone" aria-hidden="true"></i>
                        </Badge>
                      </div>
                      <div>
                        <p className="mb-0">
                          <Link
                            to="javascript:void(0)"
                            onClick={() =>
                              (window.location = "tel:02214694784")
                            }
                          >
                            0221 469-4784
                          </Link>
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </Col>
              <Col lg={6} sm={12}>
                <ul className="list-unstyled p-lg-5">
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <Badge className="badge-circle mr-3" color="default">
                          <i class="fa fa-map-marker" aria-hidden="true"></i>
                        </Badge>
                      </div>
                      <div>
                        <p className="mb-0" mail>
                          <Link
                            target="_blank"
                            to="https://www.google.com/maps/place/Ensue%C3%B1o+-+Centro+de+d%C3%ADa/@-34.8648542,-57.9097592,20.87z/data=!4m6!3m5!1s0x95a2e5c3946144f5:0xe7909975c6658f98!8m2!3d-34.864798!4d-57.909674!16s%2Fg%2F11t67rhp4y?entry=ttu"
                          >
                            25 de Mayo 251, Ensenada, Provincia de Buenos Aires
                          </Link>
                        </p>
                      </div>
                    </div>
                  </li>
                  {/* <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <Badge className="badge-circle mr-3" color="default">
                          <i class="fa fa-phone" aria-hidden="true"></i>
                        </Badge>
                      </div>
                      <div>
                        <p className="mb-0">0221 469-4784</p>
                      </div>
                    </div>
                  </li> */}
                </ul>
              </Col>
            </Row>
          </Row>
          <Row>
            <Col lg="6">
              <Card className="bg-gradient-secondary shadow">
                <CardBody className="p-lg-5">
                  <p className="mt-0">
                    Completa el formulario o envianos un mail a
                    contacto@centrodediaensuenio.com.ar
                  </p>
                  {isLoading ? (
                    <Spinner>
                      <span className=" sr-only">Loading...</span>
                    </Spinner>
                  ) : null}

                  {isSuccess ? (
                    <UncontrolledAlert color="success" fade={false}>
                      <span className="alert-inner--text">
                        <strong>Su consulta ha sido enviada!</strong> En breve
                        nos contactaremos con usted
                      </span>
                    </UncontrolledAlert>
                  ) : null}

                  {isError ? (
                    <UncontrolledAlert color="danger" fade={false}>
                      <span className="alert-inner--text">
                        <strong>Error al enviar su consulta!</strong> Por favor
                        intente más tarde
                      </span>
                    </UncontrolledAlert>
                  ) : null}
                  <form onSubmit={contactoForm.handleSubmit}>
                    <FormGroup
                      className={classnames("mt-5", {
                        focused: nameFocused,
                      })}
                    >
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fa fa-user" aria-hidden="true"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Nombre"
                          type="text"
                          name="nombre"
                          value={contactoForm.values.nombre}
                          onFocus={(e) => setNameFocused(true)}
                          onBlur={(e) => setNameFocused(true)}
                          onChange={contactoForm.handleChange}
                        />
                      </InputGroup>
                      {contactoForm.touched.nombre &&
                      contactoForm.errors.nombre ? (
                        <div className="text-danger">
                          <small>{contactoForm.errors.nombre}</small>
                        </div>
                      ) : null}
                    </FormGroup>
                    <FormGroup
                      className={classnames({
                        focused: emailFocused,
                      })}
                    >
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="E-mail"
                          type="email"
                          name="email"
                          value={contactoForm.values.email}
                          onFocus={(e) => setEmailFocused(true)}
                          onBlur={(e) => setEmailFocused(false)}
                          onChange={contactoForm.handleChange}
                        />
                      </InputGroup>
                      {contactoForm.touched.email &&
                      contactoForm.errors.email ? (
                        <div className="text-danger">
                          <small>{contactoForm.errors.email}</small>
                        </div>
                      ) : null}
                    </FormGroup>
                    <FormGroup className="mb-4">
                      <Input
                        className="form-control-alternative"
                        cols="80"
                        name="mensaje"
                        placeholder="Escriba su mensaje..."
                        rows="4"
                        type="textarea"
                        onChange={contactoForm.handleChange}
                        value={contactoForm.values.mensaje}
                      />
                      {contactoForm.touched.mensaje &&
                      contactoForm.errors.mensaje ? (
                        <div className="text-danger">
                          <small>{contactoForm.errors.mensaje}</small>
                        </div>
                      ) : null}
                    </FormGroup>
                    <div>
                      <Button
                        block
                        className="btn-round"
                        color="default"
                        size="lg"
                        type="submit"
                      >
                        Envíe su consulta
                      </Button>
                    </div>
                  </form>
                </CardBody>
              </Card>
            </Col>
            <Col lg="6">
              <MapContainer />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default ContactoLanding;
