import React from 'react';
import { Col, Container, Row } from 'reactstrap';

function Talleres(props){ 
    return (
        <>
            <section className="section section-lg pt-6 bg-secondary mt-5">
                <Container className="mb-6">
                    <Row className="row-grid align-items-center">
                        <Col className lg="12">
                        <div className="pl-4 align-component-enter">
                            <h4 className="display-3">Talleres</h4>
                        </div>
                        </Col>
                    </Row>
                    <Row className="mt-6">
                        <ul>
                            <li>Hacia la vida Independiente: elección de talleres y actividades de interés</li>
                            <li>Taller de comunicación y radio. Espacio Fonaudiología</li>
                            <li>Taller de Participación y Comunicación activa. Espacio Fonoaudiología</li>
                            <li>Taller de autodeterminación. Espacio Psicología</li>
                            <li>Taller de  Estimulación Cognitiva. Espacio Psicología</li>
                            <li>Espacio de Terapia Ocupacional.</li>
                            <li>Espacio de Trabajo Social</li>
                            <li>Espacio de trabajo de Kinesiología</li>
                            <li>Taller de Educación Física</li>
                            <li>Taller manejo de dinero</li>
                            <li>Taller de literatura y actualidad</li>
                            <li>Taller de multitarea</li>
                            <li>Actividades de la vida diaria instrumental</li>
                            <li>Tiempo libre y ocio</li>
                            <li>Taller habilidades académicas funcionales</li>
                            <li>Taller de computación</li>
                            <li>Taller de Danzas y Expresión Artística</li>
                            <li>Taller autosustentable</li>
                            <li>Taller jardinería y huerta</li>
                            <li>Taller carpintería</li>
                            <li>Taller de gastronomía</li>
                            <li>Taller de manualidades textiles</li>
                            <li>Taller de producción con yeso, cemento y cerámica en frío</li>
                            <li>Ferias propias y comunitarias </li>
                            <li>Festejos varios</li>
                            <li>Salidas a la comunidad propuestas por los espacios y salidas recreativas</li>
                            <li>Participación en torneos de Futbol</li>
                            <li>Participación de Torneos BA Deportivos y Cultura</li>
                        </ul>
                    </Row>
                </Container>
            </section>   
        </>   
    );


}


export default Talleres;