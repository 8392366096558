import Frente1 from '../../assets/img/galeria-imagenes/lugar/frente1.jpg';
import Oficina from '../../assets/img/galeria-imagenes/lugar/nuevas/oficina.jpg';
import Oficina1 from '../../assets/img/galeria-imagenes/lugar/nuevas/oficina1.jpg';
import Oficina2 from '../../assets/img/galeria-imagenes/lugar/nuevas/oficina2.jpg';
import Oficina3 from '../../assets/img/galeria-imagenes/lugar/nuevas/oficina3.jpg';
import Oficina4 from '../../assets/img/galeria-imagenes/lugar/nuevas/oficina4.jpg';
import Banio from '../../assets/img/galeria-imagenes/lugar/nuevas/banio.JPG';
import Banio1 from '../../assets/img/galeria-imagenes/lugar/nuevas/banio1.jpg';
import Banio2 from '../../assets/img/galeria-imagenes/lugar/nuevas/banio2.jpg';
import Banio3 from '../../assets/img/galeria-imagenes/lugar/nuevas/banio3.JPG';
import Comedor from '../../assets/img/galeria-imagenes/lugar/nuevas/comedor.jpg';
import Comedor1 from '../../assets/img/galeria-imagenes/lugar/nuevas/comedor1.jpg';
import Actividades from '../../assets/img/galeria-imagenes/lugar/nuevas/sala-actividades.jpg';
import Actividades2 from '../../assets/img/galeria-imagenes/lugar/nuevas/sala-actividades1.jpg';
import Computacion from '../../assets/img/galeria-imagenes/lugar/nuevas/sala-computacion.jpg';
import Computacion1 from '../../assets/img/galeria-imagenes/lugar/nuevas/sala-computacion1.jpg';
import Computacion2 from '../../assets/img/galeria-imagenes/lugar/nuevas/sala-computacion2.jpg';
import Cocina from '../../assets/img/galeria-imagenes/lugar/nuevas/cocina.JPG';
import Cocina2 from '../../assets/img/galeria-imagenes/lugar/nuevas/cocina2.jpg';
import Pasillo from '../../assets/img/galeria-imagenes/lugar/nuevas/pasillo.jpg';
import Patio from '../../assets/img/galeria-imagenes/lugar/nuevas/patio.jpg';
import Patio1 from '../../assets/img/galeria-imagenes/lugar/nuevas/patio1.jpg';

export const photosLugar = [
    {
      src: Frente1,
      width: 2,
      height: 3
    },
    {
      src: Oficina4,
      width: 2,
      height: 2
    },
    {
      src: Oficina,
      width: 2,
      height: 2
    },
    {
      src: Oficina1,
      width: 2,
      height: 2
    },
    {
      src: Oficina2,
      width: 2,
      height: 2
    },
    {
      src: Oficina3,
      width: 2,
      height: 2
    },
    {
      src: Cocina2,
      width: 3,
      height: 3
    },
    {
      src: Cocina,
      width: 2,
      height: 3
    },
    {
      src: Comedor,
      width: 4,
      height: 3
    },
    {
      src: Comedor1,
      width: 2,
      height: 3
    },
    {
      src: Actividades,
      width: 4,
      height: 3
    },
    {
      src: Actividades2,
      width: 4,
      height: 3
    },
    {
      src: Computacion,
      width: 3,
      height: 2
    },
    {
      src: Computacion1,
      width: 2,
      height: 2
    },
    {
      src: Computacion2,
      width: 2,
      height: 2
    },
    {
      src: Banio,
      width: 3,
      height: 4
    },
    {
      src: Banio1,
      width: 3,
      height: 4
    },
    {
      src: Banio2,
      width: 3,
      height: 4
    },
    {
      src: Banio3,
      width: 3,
      height: 4
    },
    {
      src: Pasillo,
      width: 3,
      height: 3
    },
    {
      src: Patio,
      width: 2,
      height: 2
    },
    {
      src: Patio1,
      width: 3,
      height: 2
    }
  ];
  